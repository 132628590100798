import { to } from 'await-to-js';
import { HttpService } from './http.service';
import { LogFactory } from '../helpers/debug';
const log = LogFactory('UserService', false);
const baseRoute = '/user';

/**
 * Returns {
 *  user: {}
 *  organization: {},
 * }
 */
let currentUserCache = null;
async function fetchLoggedUserInfo() {
  log('entered fetchLoggedUserInfo');
  if (currentUserCache) {
    return Promise.resolve(currentUserCache);
  }

  const response = await HttpService.GET(baseRoute);
  return response;
}

export async function getUserDetails() {
  log('entered getUserDetails');
  const data = await fetchLoggedUserInfo();

  return data;
}

async function UpdateUserInfoEndpoint(payload) {
  log('entered UpdateUserInfoEndpoint', { payload });

  const response = await HttpService.POST('/user', payload);
  return response;
}

class UserServiceFactory {
  async loadUserInfo() {
    await getUserDetails();
    return true;
  }
  async getCurrentUserInfo() {
    log('entered getCurrentUserInfo');
    const data = await getUserDetails();
    return data.user;
  }

  async getCurrentUserOrganization() {
    log('entered getCurrentUserOrganization');

    const data = await getUserDetails();
    return data.organization;
  }

  async UpdateUserInfo(payload) {
    log('entered UpdateUserInfo');

    const [err, response] = await to(UpdateUserInfoEndpoint(payload));
    if (err) {
      throw err;
    }

    return response;
  }

  async getWebsite(domain = '') {
    log('entered getSize', { domain });

    const formatedDomain = domain.replace('https://', '').replace('http://', '').replace('www.', '');

    const payload = {
      domain: formatedDomain
    };

    log(' ... loading website info ');

    const [err, response] = await to(HttpService.GET(`/user/website`, payload));

    log('err, response', { err, response });

    return response;
  }
}

export const UserService = new UserServiceFactory();
