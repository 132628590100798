import { to } from 'await-to-js';
import { HttpService } from './http.service';

import { LogFactory } from '../helpers/debug';
const log = LogFactory('WebinarService', false);

let CURRENT_REGISTRANT = null;
let webinarCache = null;

class WebinarServiceFactory {
  async fetchWebinar(obfuscatedID = '', pageType = 'webinars') {
    log('entered fetchWebinar');

    if (webinarCache) {
      return Promise.resolve(webinarCache);
    }

    const id = encodeURIComponent(obfuscatedID);

    const response = await HttpService.GET('/registration/' + id, { pageType });
    return response;
  }

  async createWebinarRegistrantEndpoint(obfuscatedID = '', payload) {
    log('entered createWebinarRegistrantEndpoint');

    const id = encodeURIComponent(obfuscatedID);
    const response = await HttpService.POST('/registration/' + id + '/registrant', payload);
    return response;
  }

  async approveWebinarRecordingsRegistrant(obfuscatedID = '', payload) {
    log('entered approveWebinarRecordingsRegistrant');

    const id = encodeURIComponent(obfuscatedID);
    const response = await HttpService.PUT('/registration/ondemand/' + id + '/registrantStatus', payload);
    return response;
  }

  async GetWebinar(id, pageType = 'webinars') {
    log('entered GetWebinar', { id, pageType });

    if (!id) {
      throw new Error('No ID found');
    }

    const [err, response] = await to(this.fetchWebinar(id, pageType));
    if (err) {
      throw err;
    }

    return response;
  }

  async CheckRegistrant(ObfucatedID, pageType = 'webinars') {
    log('entered GetWebinar', { ObfucatedID, pageType });

    if (!ObfucatedID) {
      throw new Error('No ID found');
    }
    const id = encodeURIComponent(ObfucatedID);
    const URL = `/registration/${id}/checkRegistrant`;
    const response = await HttpService.GET(URL, { pageType });

    return response;
  }

  async CheckMeetingRecordingRegistrant(ObfucatedID) {
    log('entered CheckMeetingRecordingRegistrant', { ObfucatedID });

    if (!ObfucatedID) {
      throw new Error('No ID found');
    }
    const id = encodeURIComponent(ObfucatedID);
    const URL = `/registration/${id}/checkMeetingRecordingRegistrant`;
    const response = await HttpService.GET(URL);

    return response;
  }

  async CreateWebinarRegistrant(payload, obfuscatedID) {
    log('entered CreateWebinarRegistrant');

    if (!obfuscatedID) {
      throw new Error('No ID found');
    }

    const response = await this.createWebinarRegistrantEndpoint(obfuscatedID, payload);

    CURRENT_REGISTRANT = response;

    return response;
  }

  async CreateOnDemandWebinarRegistrant(payload, obfuscatedID) {
    log('entered CreateWebinarRegistrant');

    if (!obfuscatedID) {
      throw new Error('No ID found');
    }

    const id = encodeURIComponent(obfuscatedID);
    const response = await HttpService.POST('/registration/ondemand/' + id + '/registrant', payload);
    return response;
  }

  async getWebinarRecordings(obfuscatedID) {
    log('entered getWebinarRecordings');

    if (!obfuscatedID) {
      throw new Error('No ID found');
    }

    const id = encodeURIComponent(obfuscatedID);
    const response = await HttpService.GET('/registration/ondemand/' + id + '/recordings');

    return response;
  }

  async getWebinarRecordingSettings(obfuscatedID) {
    log('entered getWebinarRecordings');

    if (!obfuscatedID) {
      throw new Error('No ID found');
    }

    const id = encodeURIComponent(obfuscatedID);
    const response = await HttpService.GET('/registration/ondemand/' + id + '/recordingSettings');

    return response;
  }

  GetCurrentRegistrant() {
    log('entered GetCurrentRegistrant', { CURRENT_REGISTRANT });
    if (!CURRENT_REGISTRANT) {
      throw new Error('CURRENT_REGISTRANT not found');
    }

    return {
      id: CURRENT_REGISTRANT.registrant_id,
      url: CURRENT_REGISTRANT.join_url
    };
  }
}

export const WebinarService = new WebinarServiceFactory();
