<template>
  <section class="mk-conntent section pt-2 pb-0">
    <div class="columns is-centered">
      <div class="column mk-content-wrap is-12">
        <slot></slot>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'mk-content',
  data: function() {
    return {
      theme: ''
    };
  },
  created: function() {
    if (window.THEME_IFRAME) {
      this.theme = 'iframe';
    }
  }
};
</script>
<style scoped>
.mk-content-wrap {
  max-width: 1000px;
}
</style>
