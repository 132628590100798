<template>
  <figure v-if="projectLogo" class="image">
    <img :src="projectLogo" class="webinar-logo" @error="onImageError" />
  </figure>
</template>

<script>
const LOGO_URL_DEFAULT = 'https://lf-master-project-logos-prod.s3.us-east-2.amazonaws.com/thelinuxfoundation-color.svg';
const LOGO_URL_BASE = 'https://lf-master-project-logos-prod.s3.us-east-2.amazonaws.com/';
import { getAllURLParams } from '../helpers/url';

import { LogFactory } from '../helpers/debug';
const log = LogFactory('ProjectLogo', false);

export default {
  data() {
    return {
      projectLogo: ''
    };
  },
  mounted() {
    this.projectLogo = this.getURLLogo();
  },
  methods: {
    getURLLogo() {
      const { logo } = getAllURLParams() || {};

      const logoURL = `${LOGO_URL_BASE}${logo}`;

      log('logoURL ? ', { logoURL });

      return logoURL;
    },
    onImageError() {
      this.projectLogo = LOGO_URL_DEFAULT;
    }
  }
};
</script>

<style scoped>
.webinar-logo {
  width: auto;
}
figure {
  width: 160px;
}
</style>
