<template>
  <main class="wrap-app-layout">
    <div class="">
      <AppHeader />
    </div>
    <div class="base-content pt-0" :class="{ 'is-flex-end': !$auth.isAuthenticated }">
      <template v-if="$auth.isAuthenticated">
        <b-loading :active="!isPageLoaded"></b-loading>
        <div v-if="isPageLoaded" class="current-route">
          <slot />
        </div>
      </template>
    </div>
  </main>
</template>
<script>
import querystring from 'querystring';

import { LogFactory } from '../helpers/debug';
import AppHeader from './AppHeader';

const log = LogFactory('AppLayout', false);

export default {
  components: {
    AppHeader
  },
  props: ['isAuthenticated'],
  data() {
    return {
      isPageLoaded: false
    };
  },
  mounted() {
    log('entered mounted');

    this.handleReturnTo();

    if (!this.$auth.isAuthenticated) {
      this.$auth.loginWithRedirect();
      return;
    }

    this.isPageLoaded = true;
  },
  methods: {
    handleReturnTo() {
      log('entered handleReturnTo');

      let returnTo = this.$auth.getCurrentReturnTo();

      if (returnTo) {
        const [path, search] = returnTo.split('?');
        const query = querystring.parse(search);

        log('final returnTo', { path, query });
        this.$router.push({ path, query });
      }
    }
  }
};
</script>
<style scoped>
.wrap-app-header {
  max-width: 1000px !important;
}
</style>
