//@ts-check
import Vue from 'vue';
import querystring from 'query-string';
import { isTLFWithStateInFooter, jsonFoot } from './footer/footer.features';

let instance;
export const getInstance = () => instance;

export const Service = (options) => {
  if (instance) return instance;

  instance = new Vue({
    methods: {
      async getFooterHtml() {
        const footers = jsonFoot;
        const { project = '', zoomID = '' } = querystring.parse(window.location.search);

        const needsState = isTLFWithStateInFooter(zoomID);
        if (needsState) {
          return footers['tlf-and-state'];
        }

        // @ts-ignore: Unreachable code error
        const html = footers[+zoomID] || footers[zoomID] || footers[project] || '';
        return html;
      }
    }
  });

  return instance;
};

export const FooterPlugin = {
  install(Vue, options) {
    Vue.prototype.$footer = Service(options);
  }
};
