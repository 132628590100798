<template>
  <div v-if="$auth.user" class="myprofile-dropdown font-source-sans-pro">
    <div class="user-info-wrapper" @mouseover="show" @click="show" v-click-outside="hide">
      <div class="user-level-root">
        <figure class="image is-inline-block">
          <img :src="$auth.user.picture" class="card-image is-rounded" />
        </figure>
        <span class="user-root-title"> My Profile </span>
      </div>
      <div v-if="isContentDisplayed" @mouseleave="hide" class="user-level-content">
        <div class="item"><a target="_blank" :href="url">Manage Profile</a></div>
        <div class="item"><a @click="logout">Logout</a></div>
      </div>
    </div>
  </div>
</template>
<script>
const stage = process.env.VUE_APP_STAGE || 'prod';
const myProfileURL =
  stage && stage.toLowerCase().includes('dev')
    ? 'https://myprofile.dev.platform.linuxfoundation.org/'
    : 'https://myprofile.lfx.linuxfoundation.org/';

export default {
  data() {
    return {
      url: myProfileURL,
      isContentDisplayed: false
    };
  },
  methods: {
    show() {
      this.isContentDisplayed = true;
    },
    hide() {
      this.isContentDisplayed = false;
    },
    toggleContent() {
      this.isContentDisplayed = !this.isContentDisplayed;
    },
    logout() {
      const returnTo = this.$app.returnTo;
      this.$auth.logout({
        returnTo: window.location.origin + `?returnTo=${encodeURIComponent(returnTo)}`
      });
    }
  }
};
</script>

<style scoped>
.myprofile-dropdown {
  /* position: absolute; */
  /* right: 2rem; */
  z-index: 99;
  cursor: pointer;
  color: #969696;
}

.user-info-wrapper {
  position: relative;
}

.user-level-root img {
  width: 25px;
  height: 25px;
}

.user-root-title {
  font-size: 14px;
}
.user-root-title::after {
  content: ' ▾';
}

.user-level-content {
  position: absolute;
  background: #fff;
  font-size: 14px;
  right: 0;
  top: 2rem;
}

.user-level-content .item:hover {
  background: #f6f6f6;
}

.user-level-content a {
  cursor: pointer;
  display: block;
  padding: 6px 12px;
  color: #878787;
  font-size: 14px;
  white-space: nowrap;
  text-decoration: none;
}

.user-level-content a:hover {
  color: #09c;
}

.user-root-image {
  width: 25px;
  height: 25px;
}
</style>
