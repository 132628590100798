import Vue from 'vue';
import App from './App.vue';
import router from './router';
import '@/shared/index.js';

import 'vue-multiselect/dist/vue-multiselect.min.css';

Vue.config.productionTip = true;

import { AppPlugin } from './plugins/app.plugin';
import { UserPlugin } from './plugins/user.plugin';
import { RegistrationPlugin } from './plugins/registration.plugin';
import { Auth0Plugin } from './plugins/spa.auth0.plugin';
import { ProjectPlugin } from './plugins/project.plugin';
import { FooterPlugin } from './plugins/footer.plugin';

Vue.use(AppPlugin);
Vue.use(UserPlugin);
Vue.use(RegistrationPlugin);
Vue.use(Auth0Plugin);
Vue.use(ProjectPlugin);
Vue.use(FooterPlugin);

new Vue({
  router,
  render: (h) => h(App)
}).$mount('#app');
