<template>
  <mk-content>
    <b-loading :active="isLoading"></b-loading>
  </mk-content>
</template>

<script>
import mkContent from '@/components/mkContent.vue';

export default {
  components: {
    mkContent
  },
  name: 'home',
  data() {
    return {
      isLoading: true
    };
  }
};
</script>
