<template>
  <div id="app">
    <b-loading :active="$auth.loading"></b-loading>
    <AppLayout v-if="!$auth.loading">
      <keep-alive>
        <router-view></router-view>
      </keep-alive>
    </AppLayout>
  </div>
</template>
<script>
import AppLayout from '@/components/AppLayout.vue';

export default {
  components: {
    AppLayout
  }
};
</script>
<style lang="scss">
@import './assets/style/_main.scss';
</style>
