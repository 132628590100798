import Vue from 'vue';
import Vuelidate from 'vuelidate';
import { Checkbox, Switch, Radio, Tooltip, Loading, Icon, Select, Input, Autocomplete, Field } from 'buefy';
import vSelect from 'vue-select';
import Switcher from '@/components/Switcher.vue';
import 'vue-select/dist/vue-select.css';
import './currency.filter';
import './click-outside.directive';

import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faInfo,
  faUser,
  faEnvelope,
  faMapPin,
  faGlobeAmericas,
  faMap,
  faStreetView,
  faPhoneAlt,
  faBuilding
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faGithub, faTwitter, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';

import VueMoment from 'vue-moment';
import moment from 'moment-timezone';

import Multiselect from 'vue-multiselect';

import Vuesax from 'vuesax';

import 'vuesax/dist/vuesax.css'; //Vuesax styles

Vue.component('vue-phone-number-input', VuePhoneNumberInput);

library.add(
  faInfo,
  faUser,
  faEnvelope,
  faGithub,
  faTwitter,
  faLinkedinIn,
  faMapPin,
  faGlobeAmericas,
  faMap,
  faStreetView,
  faPhoneAlt,
  faBuilding
);
Vue.component('vue-fontawesome', FontAwesomeIcon);

Vue.use(Icon);

Vue.component('multiselect', Multiselect);

Vue.use(VueMoment, {
  moment
});

Vue.use(Vuelidate);
Vue.use(Checkbox);
Vue.use(Switch);
Vue.use(Radio);
Vue.use(Tooltip);
Vue.use(Loading);
Vue.use(Select);
Vue.use(Input);
Vue.use(Autocomplete);
Vue.use(Field);

Vue.component('v-select', vSelect);
Vue.component('mk-switcher', Switcher);

Vue.use(Vuesax, {
  // options here
});
