const LIB_FS = window.FS;
import { v4 as uuid } from 'uuid';
import { getAllURLParams } from '../helpers/url';
import { LogFactory } from '../helpers/debug';
const log = LogFactory('FullStory', false);

let identified = false;

class FullStoryFactory {
  constructor() {
    this.sessionid = '';
    this.setSessionId();
    this.sendEvent('link_clicked');
  }
  setSessionId() {
    log('entered setSessionId');
    const params = getAllURLParams() || {};

    if (params.sessionid) {
      this.sessionid = params.sessionid;
      log('reusing sessionID from URL');
      log('sessionid', { sessionid: params.sessionid });
      return;
    }
    log('creating sessionid');

    this.sessionid = uuid();
    log('sessionid', { sessionid: this.sessionid });

    return true;
  }
  identify(uid, { displayName, email, username }) {
    const userVars = {
      displayName,
      email,
      username,
      sessionid: this.sessionid
    };

    log(' identity ? ', { uid, userVars, identified });

    if (identified) {
      this.setUserVars(userVars);
      return;
    }

    if (!uid) {
      this.setUserVars(userVars);
      return;
    }

    const isAuth0User = uid.includes('auth0|') || !uid.includes('|');
    log('FS identity', { isAuth0User });

    if (!isAuth0User) {
      this.setUserVars(userVars);
      return;
    }

    const innerUID = uid.replace('auth0|', '');
    log(' set identity', { innerUID, userVars });

    LIB_FS.identify(innerUID, userVars);
    identified = true;
  }

  setUserVars(data = {}) {
    log('entered setUserVars');
    const userVars = {
      ...data,
      sessionid: this.sessionid
    };

    LIB_FS.setUserVars(userVars);
  }

  sendEvent(eventName = '', payload = {}) {
    if (!eventName) {
      return;
    }

    const data = {
      ...payload,
      sessionid_str: this.sessionid
    };

    log('sendEvent data ? ', { eventName, data });

    LIB_FS.event(eventName, payload);
  }

  fsLog(message = '', type = 'log') {
    log('entered fsLog', { message, type });
    LIB_FS.log(type, message);
  }
}
const FullStoryService = new FullStoryFactory();
export default FullStoryService;
