//@ts-check
const jsonFooter = require('../registration-footers.json');

export const jsonFoot = jsonFooter;

export function isTLFWithStateInFooter(zoomID) {
  switch (zoomID) {
    case '98637037620':
      return true;
    default:
      return false;
  }
}

export function getZoomIDFromURL() {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const zoomID = params.get('zoomID');
  return zoomID;
}

export function isHarnessWebinar(zoomID = '') {
  const ids = [96639577756];

  return ids.includes(+zoomID);
}
export function isOPenNetworking(zoomID = '') {
  const ids = [99849730037];

  return ids.includes(+zoomID);
}
