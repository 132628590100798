import Vue from 'vue';
import { to } from 'await-to-js';

import { UserService } from '../services/user.service';

import { LogFactory } from '../helpers/debug';
const log = LogFactory('UserPlugin', false);

// import HttpService from '../services/http.service';
let instance;

export const getInstance = () => instance;

function GetState() {
  log('entered GetState');

  const linuxDomain = process.env.VUE_APP_AUTH0_DOMAIN;
  log('linuxDomain ?', { linuxDomain });

  switch (linuxDomain) {
    case 'sso.linuxfoundation.org':
    case 'linuxfoundation.auth0.com':
      return 'prod';
    default:
      return 'dev';
  }
}

const stage = GetState();

log('current stage ? ', { stage });

const ManageProfileMap = {
  prod: 'https://myprofile.linuxfoundation.org/',
  dev: `https://myprofile.dev.platform.linuxfoundation.org/`
};

export const Service = () => {
  if (instance) return instance;

  instance = new Vue({
    created() {},
    data() {
      return {
        profileURL: ManageProfileMap[stage],
        isAuthenticated: true,
        user: null,
        isUserLoading: true
      };
    },
    computed: {},
    methods: {
      async GetUser() {
        const [err, user] = await to(UserService.getCurrentUserInfo());
        log('err , user', { err, user });

        this.user = user;
        this.isUserLoading = false;

        return user;
      }
    }
  });

  return instance;
};

export const UserPlugin = {
  install(Vue, options) {
    Vue.prototype.$user = Service(options);
  }
};
