<template>
  <div class="app-header-comp">
    <div class="app-header-wrap columns">
      <div class="abc column px-2">
        <nav class="level is-marginless is-mobile">
          <div class="level-left">
            <div class="level-item">
              <div v-if="appIsLoaded" class="top-bar-logo">
                <ProjectLogo />
              </div>
            </div>
          </div>
          <div class="level-right">
            <MyProfile></MyProfile>
          </div>
        </nav>
      </div>
    </div>
    <div class="header-second-row">
      <div class="efg container">
        <div class="columns">
          <div class="column right">
            <a
              target="_blank"
              href="https://jira.linuxfoundation.org/plugins/servlet/theme/portal/4/create/384"
              class="get-help-button"
            >
              <span class="get-help-button-text">Get Help</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProjectLogo from './ProjectLogo';
import MyProfile from './MyProfile';
export default {
  components: {
    ProjectLogo,
    MyProfile
  },
  computed: {
    appIsLoaded() {
      return !this.$app.isAppLoading;
    }
  },
  methods: {
    getHelp() {}
  }
};
</script>
<style scoped>
.abc {
  max-width: 1000px;
  margin: 0 auto;
}

.efg {
  max-width: 935px;
}

.top-bar-logo {
  /* width: 300px; */
  display: table;
}

.header-second-row {
  background-color: #f2f2f2;
}

.get-help-container {
  position: relative;
}

.get-help-button {
  background: #ffa400;
  color: white;
  border-radius: 100px;
  height: 25px;
  font-size: 12px;
  display: inline-block;
  line-height: 25px;
  padding-left: 30px;
  padding-right: 10px;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
}

.get-help-button::before {
  content: '';
  border-radius: 100%;
  display: inline-block;
  width: 18px;
  height: 18px;
  border: 1px solid white;
  box-sizing: border-box;
  position: absolute;
  left: 5px;
  top: 4px;
}
.get-help-button::after {
  content: '?';
  border-radius: 100%;
  display: inline-block;
  box-sizing: border-box;
  position: absolute;
  left: 11px;
  top: 0px;
}
</style>
