<template>
  <div class="switch-wrapper is-size-7 has-text-left">
    <div class="field">
      <input
        type="checkbox"
        class="switch is-rtl is-small is-rounded"
        :id="id"
        :name="id"
        :checked="checked"
        :disabled="disabled"
        @change="$emit('change', $event.target.checked)"
      />
      <label class="switch-label" :for="id">
        {{ label }}
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'mk-switcher',
  props: ['checked', 'label', 'disabled'],
  model: {
    prop: 'checked',
    event: 'change'
  },
  data() {
    return {
      id: this.getRandomId()
    };
  },
  methods: {
    getRandomId() {
      const serie = () => Math.floor(Math.random() * 10000);
      return `${serie()}-${serie()}`;
    }
  }
};
</script>

<style scoped>
.switch[type='checkbox'][disabled] {
  cursor: not-allowed;
}

input:disabled {
  color: rgb(84, 84, 84);
  cursor: default;
}

.switch[type='checkbox'][disabled] + label {
  opacity: 0.5;
}
</style>
