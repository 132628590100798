import Vue from 'vue';

Vue.filter('mkCurrency', function(value) {
  if (!value) {
    return value === 0 ? 'Free' : '';
  }

  var num = Number(value);
  if (Number.isNaN(num)) {
    return value;
  }
  if (num < 1000) {
    return '$' + num + ' USD';
  }

  var str = num
    .toString()
    .split('')
    .reverse();
  str.splice(3, 0, ',');
  return '$' + str.reverse().join('') + ' USD';
});
