const enabled_envs = ['development', 'local', 'dev', 'DEV'];

const IS_DEBUG_ENABLED = enabled_envs.includes(process.env.VUE_APP_STAGE || 'dev');
export function debug(text, value = '', prefix = '') {
  if (!IS_DEBUG_ENABLED) {
    return;
  }

  if (typeof text !== 'string') {
    value = text;
    text = '(notext)';
  }

  text = `(${prefix}) ${text}`;

  const date = new Date().toISOString();

  if (!value) {
    console.log('... ' + text);
    return;
  }

  let logValue = JSON.stringify(value, null, 2);
  // let logValue = JSON.parse(JSON.stringify(value));

  if (value instanceof Error) {
    console.log(text, logValue);
    console.log(text, value);
    return;
  }
  if (value.error instanceof Error) {
    console.log(text, logValue);

    console.log(text, value.error);
    return;
  }
  if (value.err instanceof Error) {
    console.log(text, logValue);

    console.log(text, value.err);
    return;
  }

  console.group('%c' + date + '-' + text, 'color: yellow');
  console.log('... ' + text, logValue);
  console.groupEnd();
}

export const LogFactory = (prefix, enable = true) => (text = '', value = '') => {
  if (!enable) {
    return;
  }
  debug(text, value, prefix);
};
