import Vue from 'vue';
import { to } from 'await-to-js';

import { WebinarService } from '../services/webinar.service';
import { getAllURLParams } from '../helpers/url';
import { GetTimeZoneFriendlyName } from '../data/timezones';

import { LogFactory } from '../helpers/debug';
const log = LogFactory('RegistrationPlugin', false);

let instance;

export const getInstance = () => instance;

export const Service = () => {
  if (instance) return instance;

  instance = new Vue({
    data() {
      return {
        isLoading: true,
        data: null,
        error: null,
        errorMessage: '',
        isRegistrantLoading: true,
        registrant: null,
        meetingRecordingRegistrant: null
      };
    },
    computed: {
      isLfIbmSponnsered() {
        return this.data && this.data.id === 94730003042;
      },
      OnDemandEnabled() {
        return (this.data && this.data.settings && this.data.settings.on_demand) || false;
      },
      timezoneFriendlyName() {
        if (!this.data) {
          return '';
        }

        return GetTimeZoneFriendlyName(this.data.timezone);
      },
      time() {
        let startTime = '';
        if (!this.data) {
          return '';
        }

        startTime = this.data.start_time;

        if (startTime) {
          return startTime;
        }

        // For Meeting/Webinares that have  multible occurrences
        if (this.data.occurrences && this.data.occurrences.length) {
          return this.GetNextStartTime(this.data.occurrences);
        }

        // Example of recurrence
        //https://zoom.dev.platform.linuxfoundation.org/meeting/register?project=k8s&id=%2Bhcg2pCN4WCX%2FhsuGCir9pLQ08l%2FuW4nmsEJpx3BWJ3FhuPHrHUg8msuYxR3xUm9%2FdigK45qbTcRW1%2FpKus%3D&logo=k8s.svg&zoomID=96989821842
        if (this.data.recurrence && this.data.recurrence.end_date_time) {
          return this.data.recurrence.end_date_time;
        }

        return '';
      }
    },
    methods: {
      GetNextStartTime(occurrences = []) {
        if (!occurrences.length) {
          return '';
        }

        let counter = 0;
        let startTime = occurrences[counter].start_time;
        return startTime;
      },
      async GetRegistration() {
        log('entered GetRegistration');

        const { id = '' } = getAllURLParams() || {};
        const pageType = this.GetPageType();

        if (!id) {
          this.errorMessage = 'There was an error loading your Registrant. Please contact us to help you.';
          return;
        }

        const registration = await WebinarService.GetWebinar(id, pageType);

        log(' webinar', { registration });

        this.isLoading = false;
        this.data = registration;

        return registration;
      },
      GetPageType() {
        const route = window.location.pathname;
        const isMeeting = route.includes('meeting');
        if (isMeeting) {
          return 'meetings';
        }

        return 'webinars';
      },
      GetPageTypeSingular() {
        const route = window.location.pathname;
        const isMeeting = route.includes('meeting');
        return isMeeting ? 'Meeting' : 'Webinar';
      },
      async GetRegistrant() {
        log('entered GetRegistrant');

        const { id = '' } = getAllURLParams() || {};
        const obfuscatedID = id;
        const pageType = this.GetPageType();

        if (!id) {
          this.errorMessage = 'There was an error loading your Registrant. Please contact us to help you.';
          return;
        }

        this.isRegistrantLoading = true;

        const [err, registrant] = await to(WebinarService.CheckRegistrant(obfuscatedID, pageType));

        log('err, registrant', { err, registrant });
        this.isRegistrantLoading = false;

        if (err) {
          this.errorMessage =
            'There was an error loading your Registrant. Please refresh your page or contact us to help you.';
          return;
        }

        this.registrant = registrant;

        return registrant;
      },
      async GetMeetingRecordingRegistrant() {
        log('entered GetMeetingRecordingRegistrant');

        const { id = '' } = getAllURLParams() || {};
        const obfuscatedID = id;

        if (!id) {
          this.errorMessage = 'There was an error loading your Registrant. Please contact us to help you.';
          return;
        }

        this.isRegistrantLoading = true;

        const [err, meetingRecordingRegistrant] = await to(
          WebinarService.CheckMeetingRecordingRegistrant(obfuscatedID)
        );

        log('err, meetingRecordingRegistrant', { err, meetingRecordingRegistrant });
        this.isRegistrantLoading = false;

        if (err) {
          this.errorMessage =
            'There was an error loading your Registrant. Please refresh your page or contact us to help you.';
          return;
        }

        this.meetingRecordingRegistrant = meetingRecordingRegistrant;

        return meetingRecordingRegistrant;
      }
    }
  });

  return instance;
};

export const RegistrationPlugin = {
  install(Vue, options) {
    Vue.prototype.$registration = Service(options);
  }
};
