import Vue from 'vue';
import createAuth0Client from '@auth0/auth0-spa-js';
import querystring from 'querystring';

import { GetQueryParams } from '../helpers/url';

import FullStoryService from '../services/fullstory.service';

import { LogFactory } from '../helpers/debug';
const log = LogFactory('AuthPlugin');

const ProcessURLFromAuth0Redirection = () => {
  log('entered ProcessURLFromAuth0Redirection');
  let qs = window.location.search.replace('?', '');
  const queryObject = querystring.parse(qs);
  delete queryObject.state;
  delete queryObject.code;
  // delete queryObject.id;
  // delete queryObject.registrationType;

  qs = querystring.stringify(queryObject);

  const URL = window.location.origin + window.location.pathname + '?' + qs;
  window.history.replaceState({}, document.title, URL);
};

let instance;

export const getInstance = () => instance;

function getQueryParams() {
  let qs = window.location.search.replace('?', '');
  let queryObject = querystring.parse(qs);
  return queryObject;
}

function getSearchClean() {
  let queryObject = getQueryParams();

  // *todo: to avoid conflicts in next log in redirection
  delete queryObject.state;
  delete queryObject.code;

  return queryObject;
}

function getReturnto() {
  const search = getSearchClean();
  const pathname = window.location.pathname;
  const sessionid = FullStoryService.sessionid;
  const newSearch = querystring.stringify({
    ...search,
    sessionid
  });

  const returnToURL = encodeURIComponent(`${pathname}?${newSearch}`);
  return returnToURL;
}

export const useAuth0 = ({ onRedirectCallback = ProcessURLFromAuth0Redirection }) => {
  if (instance) return instance;

  instance = new Vue({
    created() {
      this.initAuth0Session();
    },
    data() {
      return {
        loading: true,
        isAuthenticated: false,
        user: null,
        auth0Client: null,
        popupOpen: false,
        error: null
      };
    },
    methods: {
      getAuth0RedirectURI() {
        const { origin } = window.location;
        const URL = `${origin}?returnTo=${getReturnto()}`;

        return URL;
      },
      /** Authenticates the user using a popup window */
      async loginWithPopup(o) {
        this.popupOpen = true;

        try {
          await this.auth0Client.loginWithPopup(o);
        } catch (e) {
          // eslint-disable-next-line
          console.error(e);
        } finally {
          this.popupOpen = false;
        }

        this.user = await this.auth0Client.getUser();
        this.isAuthenticated = true;
      },
      /** Handles the callback when logging in using a redirect */
      async handleRedirectCallback() {
        this.loading = true;
        try {
          await this.auth0Client.handleRedirectCallback();
          this.user = await this.auth0Client.getUser();
          this.isAuthenticated = true;
        } catch (e) {
          this.error = e;
        } finally {
          this.loading = false;
        }
      },
      GetPageType() {
        const route = window.location.pathname;
        const isMeeting = route.includes('meeting');
        if (isMeeting) {
          return 'meetings';
        }

        return 'webinars';
      },
      loginWithRedirect(o = {}) {
        const [, search] = window.location.search.split('?');
        let returnQuery = {};

        const { returnTo } = querystring.parse(search);

        if (returnTo) {
          const [, query] = returnTo.split('?');
          const queryValues = querystring.parse(query);
          returnQuery = { ...queryValues };
        }

        const queryData = GetQueryParams();
        const sessionid = FullStoryService.sessionid;
        const options = {
          redirect_uri: this.getAuth0RedirectURI(),
          app: 'LF Registration',
          registrationType: this.GetPageType(),
          sessionid,
          ...queryData,
          ...returnQuery,
          ...o
        };

        delete options.returnTo;
        return this.auth0Client.loginWithRedirect(options);
      },
      /** Returns all the claims present in the ID token */
      getIdTokenClaims(o) {
        return this.auth0Client.getIdTokenClaims(o);
      },
      /** Returns the access token. If the token is invalid or missing, a new one is retrieved */
      getTokenSilently(o) {
        return this.auth0Client.getTokenSilently(o);
      },
      /** Gets the access token using a popup window */

      getTokenWithPopup(o) {
        return this.auth0Client.getTokenWithPopup(o);
      },
      /** Logs the user out and removes their session on the authorization server */
      logout(o) {
        return this.auth0Client.logout(o);
      },
      getCurrentReturnTo() {
        const search = window.location.search.replace('?', '');
        const q = querystring.parse(search);

        let returnTo = q.returnTo || '';
        let counter = 0;
        while (returnTo.includes('/?returnTo')) {
          returnTo = decodeURIComponent(returnTo.replace('/?returnTo=', ''));
          counter++;
          if (counter > 10) {
            throw new Error('There is a problem with the URL');
          }
        }

        return returnTo || '';
      },
      async initAuth0Session() {
        // Create a new instance of the SDK client using members of the given options object
        const opt = {
          domain: process.env.VUE_APP_AUTH0_DOMAIN,
          client_id: process.env.VUE_APP_AUTH0_CLIENT_ID,
          cacheLocation: 'memory'
        };

        try {
          this.auth0Client = await createAuth0Client(opt);
        } catch (error) {
          this.loading = false;
        }

        try {
          // If the user is returning to the app after authentication..
          if (window.location.search.includes('code=') && window.location.search.includes('state=')) {
            // handle the redirect and retrieve tokens
            const { appState } = await this.auth0Client.handleRedirectCallback();
            // Notify subscribers that the redirect callback has happened, passing the appState
            // (useful for retrieving any pre-authentication state)
            onRedirectCallback(appState);
          }
        } catch (e) {
          this.error = e;
        } finally {
          // Initialize our internal authentication state
          this.isAuthenticated = await this.auth0Client.isAuthenticated();
          this.user = await this.auth0Client.getUser();

          this.loading = false;
        }

        if (this.error) {
          return false;
        }

        return true;
      }
    }
  });

  return instance;
};

export const Auth0Plugin = {
  install(Vue) {
    Vue.prototype.$auth = useAuth0({});
  }
};
