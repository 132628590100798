import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes = [
  { path: '/', component: Home },
  {
    path: '/webinar/register',
    name: 'Webinar Registration',
    component: () => import(/* webpackChunkName: "webinar" */ '../views/Webinar.vue')
  },
  {
    path: '/on-demand-webinar/register',
    name: 'On-demand Webinar Registration',
    component: () => import(/* webpackChunkName: "webinar" */ '../views/expired-webinar.vue')
  },
  {
    path: '/meeting/register',
    name: 'Meeting Registration',
    component: () => import(/* webpackChunkName: "webinar" */ '../views/Webinar.vue')
  },
  {
    path: '/thanks',
    name: 'Thanks',
    component: () => import(/* webpackChunkName: "thanks" */ '../views/Thanks.vue')
  }
  // { path: '*', component: NotFoundComponent }
];

const router = new VueRouter({
  mode: 'history',
  routes
});

export default router;
