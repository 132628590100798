import Vue from 'vue';
import { to } from 'await-to-js';

import { HttpService } from '../services/http.service';

import { getAllURLParams } from '../helpers/url';

import { LogFactory } from '../helpers/debug';
const log = LogFactory('ProjectPlugin', false);

let instance;

export const getInstance = () => instance;

export const Service = () => {
  if (instance) return instance;

  instance = new Vue({
    created() {},
    data() {
      return {
        isLoading: true,
        errorMessage: '',
        slug: this.GetProjetSlug(),
        isTLF: this.isTLFProject(),
        isCFF: this.isCFFProject(),
        project: null
      };
    },
    computed: {},
    methods: {
      isTLFProject() {
        const slug = this.GetProjetSlug();
        return slug === 'tlf';
      },
      isCFFProject() {
        const slug = this.GetProjetSlug();
        return slug === 'cff';
      },
      GetProjetSlug() {
        const { project } = getAllURLParams() || {};

        return project || '';
      },
      async GetProject() {
        log('entered GetProject');

        const projectSlug = this.GetProjetSlug();

        if (!projectSlug) {
          log('no project found');
          this.isLoading = false;
          return '';
        }

        const [err, project] = await to(HttpService.GET(`/projects/${projectSlug}`));
        log('err, project ? ', { err, project });

        if (err) {
          this.errorMessage = err.message || 'There was a problem loading the project';
        }

        this.project = project;
        this.isLoading = false;

        return project;
      }
    }
  });

  return instance;
};

export const ProjectPlugin = {
  install(Vue, options) {
    Vue.prototype.$project = Service(options);
  }
};
