import { parse } from 'querystring';

import { LogFactory } from './debug';
const log = LogFactory('URL helper', false);

export function GetQueryParamsFromHash() {
  const qs = parse(window.location.hash);
  return qs;
}

export function GetQueryParams() {
  const search = window.location.search.replace('?', '');
  const qs = parse(search);
  return qs;
}

function paramsFromReturnTo() {
  const query = GetQueryParams();
  let returnParams = {};

  const returnTo = decodeURIComponent(query.returnTo || '');
  if (returnTo) {
    const [pathname, urlParams] = returnTo.split('?');
    log('pathname, urlParams ? ', { pathname, urlParams });
    if (urlParams) {
      returnParams = parse(urlParams);
    }
  }

  return returnParams;
}
export function getAllURLParams() {
  const hash = GetQueryParamsFromHash();
  const query = GetQueryParams();
  const returnParams = paramsFromReturnTo();

  const all = {
    ...returnParams,
    ...query,
    ...hash
  };

  log(' .... all', { all });

  return all;
}
