import axios from 'axios';
import { to } from 'await-to-js';
import { stringify } from 'query-string';

import { getInstance } from '../plugins/spa.auth0.plugin';
import { LogFactory } from '../helpers/debug';
const log = LogFactory('HttpService', false);

const API = process.env.VUE_APP_API;

function LogAxiosError(error) {
  log('entered LogAxiosError');
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    log('data ? ', error.response.data);
  } else if (error.request) {
    log('error.request', error.request);
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
  } else {
    // Something happened in setting up the request that triggered an Error
    log('Error ? ', error.message);
  }
  // log('error config ? ', error.config);
}

async function getIDToken() {
  const authService = getInstance();
  const claims = await authService.getIdTokenClaims();
  const id_token = claims.__raw;
  return `Bearer ${id_token}`;
}

class HttpServiceFactory {
  async GET(URL, data = null) {
    log('entered GET');

    let route = API + URL;

    if (data) {
      const queryParams = stringify(data);
      route = route + `?${queryParams}`;
    }

    const request = {
      method: 'get',
      url: route,
      headers: {
        Authorization: await getIDToken(),
        'content-type': 'application/json'
      }
    };

    const [err, response] = await to(axios(request));

    if (err) {
      LogAxiosError(err);
      const error = (err.response && err.response.data) || err.message;
      throw error;
    }

    return response && response.data ? response.data : response;
  }

  async REQUEST(URL, payload, method = 'post', multipart = false) {
    log('entered REQUEST' + method);

    let route = API + URL;

    const request = {
      method,
      url: route,
      headers: {
        Authorization: await getIDToken(),
        'content-type': multipart ? 'multipart/form-data' : 'application/json'
      },
      data: payload
    };

    log('request ?', { request });

    const [err, response] = await to(axios(request));

    if (err) {
      LogAxiosError(err);
      const error = (err.response && err.response.data) || err.message;
      throw error;
    }

    return response && response.data ? response.data : response;
  }

  async POST(URL, payload, multipart = false) {
    return await this.REQUEST(URL, payload, 'post', multipart);
  }
  async PUT(URL, payload, multipart = false) {
    return await this.REQUEST(URL, payload, 'put', multipart);
  }
  async PATCH(URL, payload, multipart = false) {
    return await this.REQUEST(URL, payload, 'patch', multipart);
  }
}

export const HttpService = new HttpServiceFactory();
