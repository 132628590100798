import Vue from 'vue';
import querystring from 'query-string';

let instance;

export const getInstance = () => instance;

export const Service = () => {
  if (instance) return instance;

  instance = new Vue({
    data() {
      return {
        isAppLoading: true,
        returnTo: '',
        id: ''
      };
    },
    methods: {
      setReturnTo() {
        const { pathname, search } = window.location || {};

        const params = querystring.parse(search);
        delete params.sessionid;
        const newSearch = querystring.stringify(params);

        this.id = params.id;
        this.returnTo = `${pathname}?${newSearch}`;
        this.isAppLoading = false;
      },
      getPageType() {
        const route = window.location.pathname;
        const isMeeting = route.includes('meeting');
        if (isMeeting) {
          return 'meetings';
        }

        return 'webinars';
      }
    }
  });

  return instance;
};

export const AppPlugin = {
  install(Vue, options) {
    Vue.prototype.$app = Service(options);
  }
};
